export const appConfig =
{
  'apiKey': 'AIzaSyBpa3X5WM0xJLviVk0TcXsGF29qTK_ai6c',
  'appId': '1:613038871563:web:c193ebb9019af7d272e30e',
  'authDomain': 'schooldog-instance-haralson.firebaseapp.com',
  'measurementId': 'G-8GHLY0WLDB',
  'messagingSenderId': '613038871563',
  'project': 'schooldog-instance-haralson',
  'projectId': 'schooldog-instance-haralson',
  'showGaBranding': true,
  'storageBucket': 'schooldog-instance-haralson.appspot.com',
}
